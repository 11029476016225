import {IErrors, INewErrors} from "./types";

export function showMessage(errors: IErrors, errorTitles?: { [key: string]: string })  {
  const newErrors: INewErrors = {};
  if(errors.required) {
    newErrors.required = errorTitles?.required ?? '';
  }
  if(errors.min) newErrors.min = {
    value: errors.min,
    message: errorTitles?.min ?? '',
  };
  if(errors.max) newErrors.max = {
    value: errors.max,
    message: errorTitles?.max ?? '',
  };
  if(errors.minLength) newErrors.minLength = {
    value: errors.minLength,
    message: errorTitles?.minLength ?? '',
  };
  if(errors.maxLength) newErrors.maxLength = {
    value: errors.maxLength,
    message: errorTitles?.maxLength ?? '',
  };
  if(errors.pattern) {
    newErrors.pattern = {
      value: errors.pattern.reg ?? '',
      message: errors.pattern.message ?? '',
    };
  }
  return newErrors
}
