import { ICustomInput, IErrors } from "../../utils/types";
import {EMPLOY_DATA, getInputsForm} from "../../constants/form";
import { IFormsConfigData, IFormsConfigDataItem } from "../../configs/forms/forms.types";

export const createInputsList = (names: string[], inputs: IFormsConfigDataItem[]) => {
  if (!inputs.length) return [];

  const result: ICustomInput[]  = [];

  for(const name of names) {
    let temp = inputs.findIndex(el => el.name === name)
    if (temp !== -1) result.push(inputs[temp])
  }

  return result
}

export const getForm = (
  step: number,
  reverseInputs: [boolean, string],
  errorsState: IErrors,
  formsConfigData: IFormsConfigData | null
) => {

  const formInputs = getInputsForm(formsConfigData?.title ?? []);
  
  let formList: {
		title: string;
		inputs: string[];
	}[] = (formInputs[step - 1] ?? {});

  if (!formList) return [];

  if (!reverseInputs[0]) { // если нет дополнительного адреса
    formList = formList.map((item) => {
      return {
        title: item.title,
        inputs: item.inputs.filter(item => item !== 'different_zip' && item !== 'different_city'&& item !== 'different_street' && item !== 'different_house_number')
      }
    })
  }

  formList = formList.map((item) => {
    return {
      title: item.title,
      inputs: item.inputs.filter(item => {
        return (
          !EMPLOY_DATA.all.includes(item) || (
            EMPLOY_DATA[reverseInputs[1]]
            && EMPLOY_DATA[reverseInputs[1]].includes(item)
            && (EMPLOY_DATA.all.includes(item) || errorsState[item as keyof IErrors]
          ))
        )
      })
    }
  })

  return formList
    .map((item) => {
      return {
        title: item.title,
        inputs: createInputsList(item.inputs, (formsConfigData?.items ?? []))
      }
    })
}