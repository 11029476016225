import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../store';
import {useInView} from "react-intersection-observer";
import {RANGER_SUMMA} from "../../utils/defaultOptions";
import {Ranger} from "../../UI/Ranger";
import {PrimaryButton} from "../../UI/PrimaryButton";
import {Answer} from "../Answer";
import {FORM_LINK, NAV_LINK_1, RANGE_LINK} from "../../constants/constantApi";
import {updateMoney,} from "../../store/features/mainData/mainDataSlice";
import {ISetActiveLink} from "../../hooks/useObserver";
import HERO_IMG_CZ from '../../assets/images/hero__img_CZ.png';
import { useSendRangeDataAction } from '../../api/methods/sendUserAction/hooks';
import { sendToNextStepFormHandle } from '../../api/methods/sendUserAction/handlers';
import './hero.sass';

export function Hero({setActive}: ISetActiveLink) {
  const dispatch = useAppDispatch();

  const { ref, inView} = useInView({
    threshold: .2,
  });

  const dataConfig = useAppSelector(state => state.configs.general.data?.main);
  const commonConfig = useAppSelector(state => state.configs.general.data?.common);
  const { money, period } = useAppSelector(state => state.mainData);
  const clickID = useAppSelector(state => state.userData.clickID);

  const { isInitialMoney } = useSendRangeDataAction(money, period);

  useEffect(() => {
    if (inView) setActive(NAV_LINK_1);
  }, [inView])

  function moneyChange(money: number) {
    dispatch(updateMoney(money));
    isInitialMoney.current = false;
  }

  // function periodChange(period: number) {
  //   dispatch(updatePeriod(period));
  // }

  function onOpenFormHandler() {
    if (clickID) {
      
      sendToNextStepFormHandle(clickID, 0);
    }
  }

  return (
    <section
      className="section hero"
      id={NAV_LINK_1}
    >
      <div
        className="container hero__container"
        style={{ backgroundImage: `url("${dataConfig?.img.url || HERO_IMG_CZ}")`}}
      >
        <div className="hero__text">
          <h1 className="section__title">{dataConfig?.title}</h1>
          <ul className="hero__text-list">
            {(dataConfig?.description ?? []).map((item, index) => (
              <li className="hero__text-item" key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="hero__range" ref={ref} id={RANGE_LINK}>
          <div className="hero__range-text">
            <h2 className="hero__range-title">{dataConfig?.rangeBlock.title}</h2>
            <p className="hero__range-description">{dataConfig?.rangeBlock.description}</p>
          </div>
          <div className="range__bar">
            <Ranger
              props={commonConfig?.rangeData?.summa ?? RANGER_SUMMA}
              firstValue={money}
              onChange={moneyChange}
            />
            {/* <Ranger
              props={commonConfig?.rangeData?.period ?? RANGER_PERIOD}
              firstValue={period}
              onChange={periodChange}
            /> */}
          </div>

          <Link to={FORM_LINK}>
            <PrimaryButton
              text={commonConfig?.primaryButtonText ?? ''}
              onClick={onOpenFormHandler}
            />
          </Link>
        </div>
      </div>
      <div className="container hero__answer">
        <Answer />
      </div>
    </section>
  );
}
