import React, {useEffect} from 'react';
import { useAppSelector } from '../../store';
import logo from '../../assets/images/svg/logo.svg'
import {useInView} from "react-intersection-observer";
import {NAV_LINK_4} from "../../constants/constantApi";
import {ISetActiveLink} from "../../hooks/useObserver";
import './footer.sass';

export function Footer({setActive}: ISetActiveLink) {
  const { ref, inView} = useInView({
    threshold: .2,
  });

  const config = useAppSelector(state => state.configs.general.data?.footer);

  useEffect(() => {
    if (inView) setActive(NAV_LINK_4)
  }, [inView]);

  return (
    <footer className="footer negative" id={NAV_LINK_4} ref={ref}>
      <div className="container">
        <div className="footer__container">
          <div className="footer__left">
            <img className="footer__logo" src={config?.logo || logo} alt="footer logo"/>
            {(config?.links ?? []).map(link => (
              <a href={link.link} className="footer__link" key={link.id}>{link.title}</a>
            ))}
          </div>
          <div className="footer__right">
            {(config?.text ?? []).map((text, i) => (
              <div key={i}>
                <h3 className='footer__text-title' key={i}>{text.title}</h3>
                {text.description.map((description, j) => (
                  <p className="footer__text-description" key={`${i}_${j}`}>{description}</p>
                ))}
              </div>
            ))}
          </div>
        </div>
        {(config?.copyright ?? []).map((copy, i) => (
          <p className="footer__text-description copyright" key={i}>{copy}</p>
        ))}
      </div>
    </footer>
  );
}
