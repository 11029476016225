import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IReviewData } from "../../../configs/review/review.types";
import { LocalStorageService } from "../../../utils/localStorageService";
import { LS_REVIEW_CONFIG_KEY } from "../../../configs/storageKeys";
import { getReviews } from "../../../configs/promises";

export interface IInitialState {
	data: IReviewData | null;
	isLoading: boolean;
	error: null | string;
}
export const initialState: IInitialState = {
	data: null,
	isLoading: false,
	error: null,
}

export const getReviewsData = createAsyncThunk(
	'reviewSlice/getReviews',
	async (_, thunkApi) => {
		try {
			const cachedData = LocalStorageService.get<IReviewData, null>(LS_REVIEW_CONFIG_KEY, null);

			if (cachedData) {
				return { data: cachedData };
			}
			
			const jsonData = await getReviews();
			const	data = jsonData;

			LocalStorageService.set<IReviewData>(LS_REVIEW_CONFIG_KEY, data);

			return { data };
		} catch (e) {
      console.log(e);
			return thunkApi.rejectWithValue(String(e));
		}
	}
)

export const reviewSlice = createSlice({
	name: 'reviews',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getReviewsData.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(getReviewsData.fulfilled, (state, action) => {
			const { data } = action.payload;

			state.isLoading = false;
			state.data = data;
			state.error = null;
		});

		builder.addCase(getReviewsData.rejected, (state, action) => {
			state.isLoading  = false;
			state.error = action.payload as string;
		});
	}
});

export const {} = reviewSlice.actions;

export default reviewSlice.reducer;