import {useEffect, useState} from "react";
import { useAppSelector } from '../../store';
import classNames from "classnames";
import './steps.sass';

export function Steps() {
  const step = useAppSelector(state => state.mainData.step);
  const stages = useAppSelector(state => state.configs.general.data?.common?.steps) ?? [];
  const [width, setWidth] = useState(100)

  const stageLength = stages.length;

  useEffect(() => {
    setWidth(window.screen.width < 768 ? 100 : 100 / stageLength)
  },[window.screen.width]);

  return (
      <div className="steps">
          <ul className="steps__list">
              {stages.map((item, index) => {
                  return (
                      <li
                        className={classNames("steps__item", step === index + 1  ? 'active' : '')}
                        key={index} style={{width: `${width}%`}}
                      >
                          {/*<div className="steps__item">*/}
                            <span className="steps__item-number">
                              {index + 1}
                              <span className='steps__item-number-none'>/{stageLength}</span>
                            </span>
                            <span className="steps__item-title">{item}</span>
                          {/*</div>*/}
                      </li>
                  )}
              )}
          </ul>
      </div>

  );
}
