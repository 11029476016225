import general from './general/general.json';
import reviews from './review/review.json';
import forms from './forms/forms.json';
import offers from './offers/offers.json';

export const configs = {
	general,
	reviews,
	forms,
	offers
};
