import { v4 as uuidv4 } from 'uuid';
import { CookieService } from "../../../utils/cookieService";
import { setClickID } from "./userSlice";

export const setClickIDHandler = (dispatch: any) => {
		let clickID = CookieService.get('clickID');

		if (!clickID) {
			clickID = `${uuidv4()}_${Date.now().toString()}`;
			console.log('clickID', clickID)
			CookieService.set('clickID', clickID, 36500);
		}

		dispatch(setClickID(clickID));
};
