export const HOME_ROUTE = '/';
// export const REQUIREMENTS = 'requirements';
// export const HOW_IT_WORK = 'how-it-works';
// export const FAQ = 'faq';
// export const ACCORDION = 'accordion';
// export const CONTACTS = 'contacts';
// export const SECURITY = 'security';
// export const GALLERY = 'gallery';

// export const CONGRATULATION = 'hotovo'

export const URL_REGISTER_LEADS = 'https://lead-api.com/api/leads/register';
export const URL_USER_EVENTS = 'https://test.api.vipn.eu/api/v1/click-eventurer/events';
// export const URL_PENDING_LEADS = 'https://lead-api.com/form_container/paydayes/waiting/?is_after_reg=true';
export const PAYDAY_CZ = 'paydaycz';
export const PAYDAY_ZN = 'znvsbgxvyw5jejik';
