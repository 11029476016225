import { useEffect } from 'react';
import { useAppSelector } from '../../store';

export const LoadAnalyticsScripts = () => {
	const { data } = useAppSelector(state => state.configs.general);

  useEffect(() => {
    const addScriptToHead = async (script: any) => {
			const scriptContainer = document.createElement('div');
			scriptContainer.innerHTML = script;
			const scriptTag = scriptContainer.firstChild as HTMLScriptElement;
			document.head.appendChild(scriptTag);

			return () => {
				document.head.removeChild(scriptTag);
			};
    };

		if (data?.analytics) {
			const scriptsData = data.analytics;
			const scripts = Object.values(scriptsData);

			scripts.forEach(script => {
				addScriptToHead(script);
			});
		}
  }, [data?.analytics]);

  return null;
};
