import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { setActiveLink } from '../../store/features/mainData/mainDataSlice';
import { CardList } from '../CardList/CardList';
import { Footer } from '../Footer';
import { getOffersData, setIsViewed } from '../../store/features/offersSlice/offersSlice';
import { IOffersData } from '../../configs/offers/offers.types';
import { StateContainer } from '../../UI/StateContainer/StateContainer';
import { useSendScrollPosition } from '../../api/methods/sendUserAction/hooks';
import { sendOffersOpened } from '../../api/methods/sendUserAction/handlers';
import { CookieService } from '../../utils/cookieService';
import { Header } from '../Header';
import { Review } from '../Review';
import './OfferWall.sass';

const offersRender = (
	{ data, settings, tags }: IOffersData,
	onClickOffer: (id: number) => void,
) => {

	const best = data.slice(0, 4);
	const cards = data.slice(4);
	
	return (
		<>
			<div className="offers-title">
				<h1>
					TYTO SPOLEČNOSTI TI PŮJČÍ
				</h1>
				<h5>
					Maximální šance na schválení půjčky při žádosti u více společností.
				</h5>
			</div>
			<div className="offers-cards">
				<div className="right">
					{best.length > 0 && (
						<CardList
							data={best}
							category={'best'}
							tags={tags}
							settings={settings}
							onClickOffer={onClickOffer}
						/>
					)}
					{cards.length > 0 && (
						<CardList
							data={cards}
							tags={tags}
							settings={settings}
							onClickOffer={onClickOffer}
						/>
					)}
				</div>
			</div>
			<Review />
		</>
	)
}

export const OfferWall = () => {
	const dispatch = useAppDispatch();

	const { data, isLoading, error } = useAppSelector(state => state.offers);

	const clickId = useAppSelector(state => state.userData.clickID);

	useSendScrollPosition(clickId, 'scroll_position_offerwall');

	useEffect(() => {
		dispatch(getOffersData());
	}, [dispatch]);

	useEffect(() => {
		if (clickId) {
			sendOffersOpened(clickId);
		}
	}, [clickId]);

	const onClickOffer = (id: number) => {
		dispatch(setIsViewed(id));
	}

	useEffect(() => {
		const viewedIds = (data?.data ?? [])
			.filter(item => item.isViewed)
			.map(item => item.id);

		return () => {
			if (viewedIds.length) {
				CookieService.set<number[]>('viewedOfferIds', viewedIds);
			}
		}
	}, [data]);

	return (
		<div className="offers-container">
			<Header activeLink={''}/>
			<div className="offers-container__content">
				{(isLoading || (!data && !isLoading && !error)) && (
					<StateContainer text="Loading..." />
				)}
				{!isLoading && (error || (!error && !data)) && (
					<StateContainer text="Something went wrong. Please, try again" />
				)}
				{!isLoading && !!data && offersRender(data, onClickOffer)}
			</div>
			<footer className="offers-container__footer">
				<Footer setActive={setActiveLink}/>
			</footer>
		</div>
	)
}