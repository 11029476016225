import React, {useState} from 'react';
import { useAppSelector } from '../../store';
import {Nav} from "../../UI/Nav";
import {Burger} from "../../UI/Burger";
import classNames from "classnames";
import { IHeader } from './Header.types';
import logo from '../../assets/images/svg/logo.svg'
import './header.sass';

export function Header({activeLink}: IHeader) {
  const configLogo = useAppSelector(state => state.configs.general.data?.header.logo);
  const [isOpen, setIsOpen] = useState(false)

  const openMenu = (isOpen: boolean) => {
    setIsOpen(isOpen)
  }

  return (
    <header className="header sticky">
      <div className="container">
        <img src={configLogo || logo} alt="logo" className="logo"/>
        {!!activeLink && (
          <>
            <Nav isOpen={isOpen} openMenuBurger={openMenu} activeLink={activeLink}/>
            <Burger isOpen={isOpen} openMenuBurger={openMenu}/>
          </>
        )}
      </div>
    </header>
  );
}
