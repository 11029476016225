import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import {useEffect, useState} from "react";
import {CustomForm} from "./CustomForm";
import { getForm } from './helpers';
import { PENDING, REDIRECT_URL } from "../../constants/constantApi";
import { updateStep } from "../../store/features/mainData/mainDataSlice";
import './customform.sass';
import './range.sass';

export function CustomFormContainer() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    errors: errorsState,
    error: commonError,
  } = useAppSelector(state => state.formData);

  const formsConfig = useAppSelector(state => state.formData.configForms);

  const {
    step,
    stepsLength,
  } = useAppSelector(state => state.mainData);
  
  const [reverseInputs, setReverseInputs] = useState<[boolean, string]>([false, '']) // [different_address, src_of_income]

  const isRedirect = step > stepsLength;

  const isIframe = location?.state?.isIframe;

  useEffect(() => {
    if (isRedirect) {
      navigate('/' + PENDING, { state: { isIframe } });

      dispatch(updateStep(3));
    };
  }, [step, stepsLength]);

  useEffect(() => {
    if (commonError) {
      window.location.href = REDIRECT_URL;
    }
  }, [commonError]);

  const handleInputs = (arr:[boolean, string]) => {
    setReverseInputs(arr)
  }

  const form = getForm(step, reverseInputs, errorsState, formsConfig.data);

  return (isRedirect || commonError)
    ? null
    : <CustomForm form={form} handleInputs={handleInputs} isIframe={isIframe} />;
}

