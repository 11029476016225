import React, {Fragment, useEffect, useState} from "react";
import { useAppDispatch, useAppSelector } from '../../store';
import {FieldErrors, SubmitHandler, useForm} from "react-hook-form";
import classNames from "classnames";
import InputMask from 'react-input-mask';
import {Header} from "../Header";
import {Inputs} from "../../utils/types";
import {showMessage} from "../../utils/functions";
import {Preloader} from "../../UI/Preloader";
import {Footer} from "../Footer";
import {Steps} from "../../UI/Steps";
import {Ranger} from "../../UI/Ranger";
import {RANGER_SUMMA} from "../../utils/defaultOptions";
import {updateMoney, updateOldStep, updateStep} from "../../store/features/mainData/mainDataSlice";
import { sendFormData } from "../../store/features/formSlice/formSlice";
import { LocalStorageService } from "../../utils/localStorageService";
import { ICustomForm } from "./Form.types";
import { useSendRangeDataAction } from "../../api/methods/sendUserAction/hooks";
import { sendInputFilledAction, sendNotValidInputField} from "../../api/methods/sendUserAction/handlers";
import './customform.sass';
import './range.sass';

export const getErrorsList = (errors: FieldErrors<Inputs>) => {
  return Object.keys(errors)
    .filter(field => field !== 'agreement');
}
export function CustomForm({form, handleInputs, isIframe}: ICustomForm) {
  const dispatch = useAppDispatch();

  const clickId = useAppSelector(state => state.userData.clickID);

  const {
    step,
    oldStep,
    stepsLength,
    money,
    period
  } = useAppSelector(state => state.mainData);

  const {errors:  errorsState, loading, data} = useAppSelector(state => state.formData);
  const commonConfig = useAppSelector(state => state.configs.general.data?.common);
  const formConfig = useAppSelector(state => state.formData.configForms);

  const [newActiveLink, setActiveLink] = useState('');

  const { isInitialMoney } = useSendRangeDataAction(money, period);
  
  let isErrors = step === oldStep;

  const {
    register,
    watch,
    formState: {
      errors,
    },
    handleSubmit,
    setValue
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues: LocalStorageService.get('form-data', {}),
  });

  function setNewActiveLink(event: string) {
    setActiveLink(event)
  }

  useEffect(() => {
    handleInputs([Boolean(watch('different_address')), String(watch('src_of_income'))])
  }, [watch('different_address'), watch('src_of_income')]);

  const onSendNotValidField = (clickId: string, name: string) => {
    const errorsList = getErrorsList(errors);
    const isValid = !errorsList.includes(name);

    if (!isValid) {
      sendNotValidInputField(clickId, `not_valid_${name}`, true);
    }
  }

  const submit: SubmitHandler<Inputs> = (data) => {
    dispatch(sendFormData(data))
  }

  const backLink = () => {
    const currentStep = step - 1;

    dispatch(updateStep(currentStep));
    dispatch(updateOldStep(0))

    // if (clickID) {
    //   sendToNextStepFormHandle(clickID, currentStep);
    // }
  }

  function moneyChange(money: number) {
    dispatch(updateMoney(money));
    isInitialMoney.current = false;
  }


  // function periodChange(period: number) {
  //   dispatch(updatePeriod(period));
  // }

  // function goToNextStepFormHandler() {
  //   if (clickID) {
  //     sendToNextStepFormHandle(clickID, step + 1);
  //   }
  // }

  // const onCheckboxChange = (name: string) => {
  //   return (e: any) => {
  //     const checked = e.target.checked;
    
  //     if (clickId) {
  //       sendInputFilledAction(clickId, name, true);
  //     }
  
  //     setValue(name, checked);
  //   }
  // }

  const onInputChange = (name: string) => {
    return (e: any) => {
      const value = e.target.value;

      if (clickId) {

        if (value) {
          const v = name === 'your_income' || name === 'your_expenses'
            ? Number(value)
            : value;

          sendInputFilledAction(clickId, name, v);
        }

        onSendNotValidField(clickId, name);
      }
  
      setValue(name, value);
    }
  }

  const onRadioChange = (name: string) => {
    return (e: any) => {
      const value = e.target.value;

      if (clickId) {
        sendInputFilledAction(clickId, name, value);
      }
  
      setValue(name, value);
    }
  }
  
  return (
    <>
      {!isIframe && <Header  activeLink={newActiveLink} />}
      
      {!loading ?
        (<main className="main form-main">

          {!isIframe && (
            <div className="form__container">
              <Steps />
            </div>
          )}

          <div className="form__container">
            <div className="range">
              <div className="range__text">
                <h2 className="range__title">{formConfig?.data?.range.title}</h2>
                <p className="range__description">{formConfig?.data?.range.description}</p>
              </div>
              <div className="range__bar">
                {(errorsState.requested_amount|| errorsState['period']) && (
                  <p className='form__input-error'>
                    {errorsState['requested_amount'] && errorsState['requested_amount'][0]} {errorsState['period'] && errorsState['period'][0]}
                  </p>)}
                <Ranger
                  props={commonConfig?.rangeData?.summa ?? RANGER_SUMMA}
                  firstValue={money}
                  onChange={moneyChange}
                />
                {/* <Ranger
                  props={commonConfig?.rangeData?.period ?? RANGER_PERIOD}
                  firstValue={period}
                  onChange={periodChange}
                /> */}
              </div>
            </div>
          </div>

          <form className="form" onSubmit={handleSubmit(submit)} key={step} autoComplete="off">
          {form.map(formItem => (
            <div className="form__container" key={formItem.title}>
              <div className="form-container">
                <h2 className="section__title">
                  {formItem.title}
                </h2>
                {formItem.inputs.length > 0 && formItem.inputs.map(item => (
                  <Fragment key={item.name}>
                    { item.input_type === 'checkbox' && (
                      <div className='form__checkbox-container' key={`checkbox-${item.name}`}>
                        <input
                          key={item.id}
                          id={item.name}
                          className='form__checkbox-input'
                          type='checkbox'
                          {...register(item.name, {required: item.validate.required && formConfig?.data?.errors.required} )}
                          aria-invalid = {errorsState.hasOwnProperty(item.name)}
                          // onChange={onCheckboxChange(item.name)}
                        />
                        <label
                          className={classNames(
                            'form__checkbox-label',
                            {'form__checkbox-label_error':
                              (errors[item.name] || (errorsState.hasOwnProperty(item.name) && isErrors))}
                          )}
                            htmlFor={item.name}
                        />
                        <p
                          className={classNames(
                            'form__checkbox-description',
                            {'form__checkbox-description_error': (errors[item.name] || (
                              errorsState.hasOwnProperty(item.name) && isErrors
                            ))}
                          )}>
                            {item.label}
                          </p>
                      </div>
                    )}

                    { item.input_type === 'radio' && (
                      <div className={classNames('form__container-radio')} key={`radio-${item.name}`}>
                        <label className='form__label' htmlFor={item.name}>
                          {item.label && item.label}
                        </label>
                        <div
                          className={classNames(
                            'form__radio-container',
                            item.grid ? `form__container-grid-${item.grid}` : `form__container-grid-1`
                          )}
                        >
                          {item.selects && item.selects.map((select, i) => (
                            <div className={classNames('form__radio-item')} key={`${item.name}-${select.value}`}>
                              <input
                                id={`${item.name}-${i}`}
                                className={
                                  classNames(
                                    'form__radio-input',
                                    {'form__radio-input_error': (
                                      errors[item.name] || (errorsState.hasOwnProperty(item.name) && isErrors)
                                    )}
                                  )}
                                type='radio'
                                value={select.value}
                                {...register(item.name,
                                  {required: item.validate.required && formConfig?.data?.errors.required})}
                                onChange={onRadioChange(item.name)}
                              />
                              <label
                                className='form__radio-label'
                                htmlFor={`${item.name}${i}`}
                              />
                              <p className='form__radio-description'>{select.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    { item.input_type === 'select' && (
                      <div
                        key={`select-${item.name}`}
                        className={classNames(
                          'form__input-container',
                          item.grid ? `form__grid-item-${item.grid}` : `form__grid-item-1`,
                        )}
                        >
                        {(errors[item.name] || (errorsState.hasOwnProperty(item.name) && isErrors)) && (
                          <p className='form__input-error'>
                              {errors[item.name]?.message || errorsState[item.name][0]}
                          </p>
                        )}
                        <select
                          className='form__select form__input'
                          {...register(item.name)}
                          onChange={onInputChange(item.name)}
                        >
                          { item.placeholder &&
                            (<option value={''} key={`${item.name}-null`} hidden>
                              {item.placeholder}
                            </option>)
                          }
                          {item.selects?.map(select => (
                            <option value={select.value} key={select.value}>{select.label}</option>
                          ))}
                        </select>
                      </div>
                    )}

                    { item.input_type !== 'checkbox' && item.input_type !== 'radio' && item.input_type !== 'select' && (
                      <div
                        className={classNames(
                          'form__input-container',
                          item.grid ? `form__grid-item-${item.grid}` : `form__grid-item-1`
                        )} key={`text-${item.name}`}
                      >
                        {(errors[item.name] || (errorsState.hasOwnProperty(item.name) && isErrors)) && (
                          <p
                            className='form__input-error'>{errors[item.name]?.message || errorsState[item.name][0]}
                          </p>
                        )}
                        <InputMask
                          mask={item.validate?.pattern && item.validate.pattern.mask  ? item.validate.pattern.mask : ''}
                          maskPlaceholder={null}
                          id={item.name}
                          className={classNames('form__input', {'form__input_error': (errors[item.name] || (errorsState.hasOwnProperty(item.name) && isErrors))} ) }
                          type={item.input_type}
                          placeholder={item.label}
                          // @ts-ignore
                          {...register(item.name, showMessage(item.validate, formConfig.data?.errors))}
                          aria-invalid = {errorsState.hasOwnProperty(item.name)}
                          onBlur={onInputChange(item.name)}
                        />
                        {item?.description && <p className='form__input-descr'>{item.description}</p>}
                      </div>
                    )}
                  </Fragment>)
                )}
              </div>
            </div>
          ))}

          <div className="form__container">
            <div className="form-container">
              {step > 1 && (
                <button
                  className="form__back-button btn"
                  type="submit"
                  onClick={backLink}
                >
                  {formConfig?.data?.buttons.prev}
                </button>
              )}
              <button
                className={classNames("btn form__submit", step > 1 ? '' : 'form__grid-item-1')}
                type="submit"
                // onClick={goToNextStepFormHandler}
              >
                {step === stepsLength ? formConfig?.data?.buttons.finish : formConfig?.data?.buttons.next}
              </button>
            </div>
          </div>
        </form>
      </main>)
      :
        <Preloader />
      }

      {!isIframe && <Footer setActive={setNewActiveLink} />}
    </>
  );
}

