import { Inputs } from "../../../utils/types";
import { RootState } from "../../rootReducer";

export const getRequestParams = (currentState: RootState, data: Inputs) => {
	// getting current state
	const mainData = currentState.mainData;
	const formData = currentState.formData.data;

	// creating URLSearchParams object to make query string
	const params = new URLSearchParams({
		requested_amount: mainData.money.toString(),
		period: mainData.period.toString(),
		aff: '710',
		agree: '1'
	});

	// if address has no difference adding contacts data
	if (!formData.different_address) {
		params.append('contact_zip', formData.zip_code);
		params.append('contact_city', formData.city);
		params.append('contact_street', formData.street);
		params.append('contact_house_number', formData.house_number);
		params.append('contact_home_status', formData.home_status);
	}

	// processing the form data
	Object.entries(data).forEach(([p, value]) => {
		const prop = convertFields(p);

		if (prop === 'bank_acc_number') {
			const currentValue = String(value);
			const hasDivider = currentValue.split('').includes('-');

			const [number, prefix] = hasDivider ? currentValue.split('-').reverse() : [currentValue];

			params.append('bank_account_prefix', (prefix || '000000').padStart(6, '0'));
			params.append('bank_account_number', (number || prefix).padStart(10, '0'));
		} else {
			params.append(prop, value.toString());
		}
	});

	// converting URLSearchParams object to string
	const url = params.toString();

	return url;
}

const convertFields = (prop: string) => {
	switch (prop) {
		case 'zip_code':
			return 'zip';
		case 'soc_sec_number':
			return 'birth_number';
		case 'phone':
			return 'cell_phone';
		case 'different_zip':
			return 'contact_zip';
		case 'different_city':
			return 'contact_city';
		case 'different_street':
			return 'contact_street';
		case 'different_house_number':
			return 'contact_house_number';
		case 'number_of_supp_children':
			return 'dependent_children';
		case 'op_number':
			return 'identity_card_number';
		case 'src_of_income':
			return 'income_type';
		case 'your_expenses':
			return 'monthly_expenses';
		case 'your_income':
			return 'monthly_income';
		case 'employer_addr':
			return 'employer_address';
		case 'name_of_employer':
			return 'employer';
		case 'how_long_had_income':
			return 'employed_time';
		case 'job_position':
			return 'job_title';
		default:
			return prop;
	}
}