import { findTag } from "../../utils/cardList";
import { ICardListComponent } from "./CardList.types";
import { useAppSelector } from "../../store";
import { sendUserAction } from "../../api/methods/sendUserAction/sendUserAction";
// import { useSendDelayOnOfferAction } from "../../api/methods/sendUserAction/hooks";
import logo from '../../assets/images/gallery/bank.png';
import './CardList.sass';

export function CardList({
	data,
	tags,
	settings,
	category = 'all',
  onClickOffer,
}: ICardListComponent) {
  const clickID = useAppSelector(state => state.userData.clickID);

  // const {
  //   onAction: onSendBlockDelay,
  //   onStopTimer: onStopBlockDelayTimer,
  // } = useSendDelayOnOfferAction(clickID);

  // const {
  //   onAction: onSendButtonDelay,
  //   onStopTimer: onStopButtonDelayTimer,
  // } = useSendDelayOnOfferAction(clickID);
  
  // const onSendOfferAction = (
  //   name: string,
  //   delay: number,
  //   onAction: (name: string, delay: number) => void,
  //   onStopTimer: () => void,
  // ) => {
  //   return (event: any) => {
  //     if (event.target === event.currentTarget) {
  //       onAction(name, delay);
  //       onStopTimer();
  //     }
  //   }
  // };

  const goToOffer = () => {
    sendUserAction<number>(clickID, [{ name: 'offer_click', value: 1 }]);
  }

  const onClickOfferHandle = (id: number) => {
    onClickOffer(id);
    sendUserAction<number>(clickID, [{ name: 'offer_click', value: 1 }]);
  }

  return (
    <ul
			className={`card-list ${category} ${data.length >= 4 ? 'grid' : ''} ${data.length < 4 ? 'flex' : ''}`}
		>
      {data.map((item) => (
        <li className={`item ${item.isViewed ? 'viewed' : ''}`} key={item.id}>
          <div
            className="card"
            // onMouseEnter={
            //   onSendOfferAction(`delayed_offer_${item.title}`, 3000, onSendBlockDelay, onStopButtonDelayTimer)
            // }
            // onMouseLeave={onStopBlockDelayTimer}
          >
            <img className="img" src={item.img || logo} alt={item.title} height={35} width={200}/>

            <div className="tags">
              {item.tagId.map((tag) => (
                <button className="tag" key={tag}>#{findTag(tag, tags)}</button>
              ))}
            </div>

            <div className="prop">
              <span className="hidomd">{settings.rangeMoney.title}</span>
              <span><strong>{item.money}</strong></span>
            </div>
            <div className="hidomd">
              <div className="prop">
                <span>{settings.rangeTerm.title}</span>
                <span><strong>{item.term}</strong></span>
              </div>
            </div>

            <a
             
              href={item.link}
             
              className="link"
             
              target={'_blank'}
             
              rel="noreferrer"
            
              // onMouseOver={
              //   onSendOfferAction(`delayed_btn_offer_${item.title}`, 5000, onSendButtonDelay, onStopBlockDelayTimer)
              // }
              // onMouseLeave={onStopButtonDelayTimer}
              onClick={() => onClickOfferHandle(item.id)}
            >
              {settings.linkText}
            </a>
          </div>
        </li>
      ))}
    </ul>
  );
}
