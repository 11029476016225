import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, sortBy } from 'lodash';
import { IOfferItem, IOffersData } from "../../../configs/offers/offers.types";
import { LocalStorageService } from "../../../utils/localStorageService";
import { LS_OFFERS_CONFIG_KEY } from "../../../configs/storageKeys";
import { getOffers } from "../../../configs/promises";
import { CookieService } from "../../../utils/cookieService";

export interface IInitialState {
	data: IOffersData | null;
	isLoading: boolean;
	error: null | string;
}

export const initialState: IInitialState = {
	data: null,
	isLoading: false,
	error: null,
}

export const setViewedOffer = (offersData: IOfferItem[], viewedList: string[]) => {
	const best: IOfferItem[] = [];
	const cards: IOfferItem[] = [];
	
	offersData.forEach(offer => {
		if (viewedList.includes(String(offer.id))) {
			cards.push({ ...offer, isViewed: true, isBestOffer: false });
		} else {
			best.push({ ...offer });
		}
	})

	return [...best, ...cards];
}

export const getSeparatedOffersData = (data: IOfferItem[]) => {
	const { best, cards } = data.reduce((res: { best: IOfferItem[], cards: IOfferItem[] }, item) => {
		if (item.isBestOffer) res.best.push({...item});
		else res.cards.push({...item});

		return res;
	}, {
		best: [],
		cards: [],
	});

	return { best, cards };
}

export const getShackedOffersData = ({ best, cards }: { best: IOfferItem[], cards: IOfferItem[] }) => {
	if (best.length < 4) {
		const count = 4 - best.length;

		const { removed, main } = cards.reduce((acc: {removed: IOfferItem[], main: IOfferItem[]}, item, idx) => {
			if (idx < count) {
				acc.removed.push({...item});
			} else {
				acc.main.push({...item});
			}

			return acc;
		}, {
			removed: [], main: [],
		});

		const currentBest: IOfferItem[] = [...cloneDeep(best), ...removed]
			.map(offer => ({ ...offer, isBestOffer: true, isViewed: false }));

		const currentCards: IOfferItem[] = main;

		

		return [...currentBest, ...currentCards];
	} else {
		return [...best, ...sortByIsViewed(cards)];
	}
}

export const getInitialSortedOffersData = (data: IOfferItem[], viewedList: string[]) => {
	const offersData = setViewedOffer(data, viewedList);
	const separatedData = getSeparatedOffersData(offersData);

	const result = getShackedOffersData(separatedData);

	return result;
}

function sortByIsViewed(data: IOfferItem[]) {
	const arr = cloneDeep(data);

	return arr.sort((a, b) => {
		if (!a.isViewed && b.isViewed) {
				return -1;
		} else if (a.isViewed === true && !b.isViewed) {
				return 1;
		} else {
				return 0;
		}
	});
}

export const getOffersData = createAsyncThunk(
	'offersSlice/getOffers',
	async (_, thunkApi) => {
		try {
			const cachedData = LocalStorageService.get<IOffersData, null>(LS_OFFERS_CONFIG_KEY, null);

			let currentData: IOffersData | null = null;


			if (cachedData) {
				currentData = cachedData
			} else {
				const jsonData = await getOffers();
				currentData = jsonData;
			}

			const cashedViewedIds = CookieService.get('viewedOfferIds');

			const viewedOfferIds = cashedViewedIds
				? cashedViewedIds.split(',')
				: [];

			const data = {
				data: getInitialSortedOffersData(currentData.data, viewedOfferIds),
				tags: currentData.tags,
				settings: currentData.settings,
			}
			
			LocalStorageService.set<IOffersData>(LS_OFFERS_CONFIG_KEY, data);

			return { data };
		} catch (e) {
      console.log(e);
			return thunkApi.rejectWithValue(String(e));
		}
	}
);

export const reviewSlice = createSlice({
	name: 'reviews',
	initialState,
	reducers: {
		setIsViewed(state, action: PayloadAction<number>) {
			if (state.data) {
				const dataCopy = cloneDeep(state.data.data);

				let viewed: IOfferItem[] = [];
				let notViewed: IOfferItem[] = [];

				dataCopy.forEach(offer => {
					if (offer.id === action.payload && !offer.isViewed) {
						viewed.push({ ...offer, isViewed: true, isBestOffer: false });
					} else {
						notViewed.push({...offer});
					}
				});

				const updatedData = [...notViewed, ...viewed];
				const separatedData = getSeparatedOffersData(updatedData);
				const result = getShackedOffersData(separatedData);

				state.data.data = result;
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getOffersData.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(getOffersData.fulfilled, (state, action) => {
			const { data } = action.payload;

			state.isLoading = false;
			state.data = data;
			state.error = null;
		});

		builder.addCase(getOffersData.rejected, (state, action) => {
			state.isLoading  = false;
			state.error = action.payload as string;
		});
	}
});

export const { setIsViewed } = reviewSlice.actions;

export default reviewSlice.reducer;
