import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { IObject, Inputs } from "../../../utils/types"
import { PAYDAY_CZ, PAYDAY_ZN, URL_REGISTER_LEADS } from "../../../assets/helpers/constantAPI";
import { setUrlPending, updateOldStep, updateStep } from "../mainData/mainDataSlice";
import { apiService } from "../../../api/AxiosService";
import { RootState } from "../../rootReducer";
import { getRequestParams } from "./helpers";
import { LocalStorageService } from "../../../utils/localStorageService";
import { IFormsConfigData } from "../../../configs/forms/forms.types";
import { LS_FORMS_CONFIG_KEY } from "../../../configs/storageKeys";
import { getFormsConfigData } from "../../../configs/promises";
import { sendToNextStepFormHandle } from "../../../api/methods/sendUserAction/handlers";
import { getInputsForm } from "../../../constants/form";

export type IInitialState = {
	loading: boolean;
	error: string;
	errors: { [key: string]: string };
	data: any;
	successStep: number;
	configForms: {
		data: null | IFormsConfigData;
		error: null | string;
		isLoading: boolean;
	}
}

export const initialState: IInitialState = {
	loading: false,
	error: '',
	errors: {},
	data: LocalStorageService.get<IObject<any>, IObject<any>>('form-data', {}),
	successStep: 0,
	configForms: {
		data: null,
		error: null,
		isLoading: false,
	}
}

export const getFormsConfig = createAsyncThunk(
	'formData/getFormsConfig',
	async (_, thunkApi) => {
		try {
			const cachedData = LocalStorageService
			.get<IFormsConfigData, null>(LS_FORMS_CONFIG_KEY, null);

			if (cachedData) {
				return { data: cachedData };
			}

			const data = await getFormsConfigData();

			LocalStorageService.set<IFormsConfigData>(LS_FORMS_CONFIG_KEY, data);

			return { data };
		} catch (e) {
      console.log(e);
			return thunkApi.rejectWithValue(String(e));
		}
	}
);

export const sendFormData = createAsyncThunk(
	'formData/sendFormData',
	async (data: Inputs, thunkApi) => {
		LocalStorageService.set<string>('form-data', JSON.stringify(data));

		const currentState = thunkApi.getState() as RootState;
		const step = currentState.mainData.step;
		const clickId = currentState.userData.clickID;

		const urlParams = getRequestParams(currentState, data);
		
		let errors: IObject<string[]> = {};

		try {
			const result = await apiService.post(
				`${URL_REGISTER_LEADS}/${currentState.mainData.money > 9e3 ? PAYDAY_ZN : PAYDAY_CZ}/`, urlParams
			);

			const status = result.data.status;
			const dataErrors = result.data.errors;

			if ( status === 'FAIL' ) {
				errors = result.data.errors;

				if (dataErrors.hasOwnProperty('bank_account_prefix')) {
					errors['bank_acc_number'] = result.data.errors['bank_account_prefix']
				}

				if (dataErrors.hasOwnProperty('bank_account_number')) {
					errors['bank_acc_number'] = result.data.errors['bank_account_number']
				}

				const inputsForm = getInputsForm(currentState.formData.configForms.data?.title ?? [])
				const formStepList = (inputsForm[currentState.mainData.step - 1] ?? []).map(item => item.inputs);
	
				const isEmptyStepError = formStepList[0]
					.filter(item => errors[item])
					.map(item => errors[item])
					.length === 0;

				if (isEmptyStepError) {
					if (step < currentState.mainData.stepsLength) {
						thunkApi.dispatch(updateStep(step + 1));

						if (clickId)
							sendToNextStepFormHandle(clickId, step);
					}
				} else {
					thunkApi.dispatch(updateOldStep(currentState.mainData.step));
				}
			} else {
				thunkApi.dispatch(updateStep(currentState.mainData.stepsLength + 1));
				thunkApi.dispatch(setUrlPending(result.data.step));

				if (clickId)
					sendToNextStepFormHandle(clickId, step);
			}

			return {
				errors,
				data,
			}

		} catch (e) {
      console.log(e);
			return thunkApi.rejectWithValue(String(e));
		}
	}
)

export const formSlice = createSlice({
	name: 'formData',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(sendFormData.pending, (state, action) => {
			state.loading = true;
		});

		builder.addCase(sendFormData.fulfilled, (state, action) => {
			const { data, errors } = action.payload;

			state.loading = false;
			state.data = data;
			state.errors = errors as any;
		});

		builder.addCase(sendFormData.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload as string;
		});

		builder.addCase(getFormsConfig.pending, (state, action) => {
			state.configForms.isLoading = true;
		});

		builder.addCase(getFormsConfig.fulfilled, (state, action) => {
			const { data } = action.payload;

			state.configForms.isLoading = false;
			state.configForms.data = data;
			state.configForms.error = null;
		});

		builder.addCase(getFormsConfig.rejected, (state, action) => {
			state.configForms.isLoading = false;
			state.configForms.error = action.payload as string;
		});
	}
});

// export const {} = formSlice.actions;

export default formSlice.reducer;
