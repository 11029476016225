import React from 'react';
import {Link} from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../store';
import {updateStep} from "../../store/features/mainData/mainDataSlice";
import './notfound.sass';

export function Notfound() {
  const step = useAppSelector(state => state.mainData.step)
  const dispatch = useAppDispatch()

  const backLink = () => {
    dispatch(updateStep(step - 1))
  }
  return (
    <div className="not-found">
      <div className="not-found__wrap">
        <p className="not-found__descr">Кажется вы заблудились</p>
        <Link to={'/'}><button className="not-found__link">На главную</button></Link>
        <button className="not-found__link" onClick={backLink}>На предыдущую</button>
      </div>
    </div>
  );
}
