import { IFormsConfigData } from "./forms/forms.types";
import { IGeneralConfig } from "./general/general.types";
import { IOffersData } from "./offers/offers.types";
import { IReviewData } from "./review/review.types";
import { apiService } from "../api/AxiosService";
import { configs } from ".";

const offers = process.env.REACT_APP_CONFIG_OFFERS ?? '';
const forms = process.env.REACT_APP_CONFIG_FORMS ?? '';
const reviews = process.env.REACT_APP_CONFIG_REVIEW ?? '';
const general = process.env.REACT_APP_CONFIG_GENERAL ?? '';

console.log('')
console.log('process.env', process.env)
console.log('offers', offers)
console.log('forms', forms)
console.log('reviews', reviews)
console.log('general', general)
console.log('')

export const getGeneralConfigs = async (): Promise<IGeneralConfig> => {
	try {
		if (general) {
			const response = await apiService.get(general);
			const data = response?.data;
			const res: IGeneralConfig = data && typeof data === 'object'
				? response.data
				: configs.general;
			console.log('===== getGeneralConfigs =======')
			console.log('response', response)
			console.log('')
			return res;
		}

		return configs.general;
	} catch (e) {
		return configs.general;
	}
};

export const getReviews = async (): Promise<IReviewData> => {
	try {
		if (reviews) {
			const response = await apiService.get(reviews);
			const data = response?.data;
			const res: IReviewData = data && typeof data === 'object'
				? response.data
				: configs.reviews;
			return res;
		}
		
		return configs.reviews;
	} catch (e) {
		return configs.reviews;
	}
};

export const getFormsConfigData = async (): Promise<IFormsConfigData> => {
	try {
		if (forms) {
			const response = await apiService.get(forms);
			const data = response?.data;
			const res: IFormsConfigData = data && typeof data === 'object'
				? response.data
				: configs.forms;
			return res;
		}

		return configs.forms;
	} catch (e) {
		return configs.forms;
	}
};

export const getOffers = async (): Promise<IOffersData> => {
	try {
		if (offers) {
			const response = await apiService.get(offers);
			const data = response?.data;
			const res: IOffersData = data && typeof data === 'object'
				? response.data
				: configs.offers;
			return res;
		}

		return configs.offers;
	} catch (e) {
		return configs.offers;
	}
};
